import Title from 'components/common/Title';
import { useIntl } from 'react-intl';
import Content from 'components/common/Content-v2';
import MembershipsTable from 'components/tables/memberships';
const Memberships = () => {
	const { formatMessage } = useIntl();
	return (
		<>
			<Title>
				<h1>{formatMessage({ id: 'memberships.title' })}</h1>
			</Title>
			<Content>
				<MembershipsTable />
			</Content>
		</>
	);
};
export default Memberships;
